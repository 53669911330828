import FeaturedTags from "~/components/threads/FeaturedTags";
import * as React from "react";
import {
  Link,
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate
} from "@remix-run/react";
import type { LoaderFunction } from "@remix-run/node";
import { json, redirect } from "@remix-run/node/dist";
import type {
  FeaturedThreadTags,
  PossibleThreadContent,
  ThreadIndexed
} from "~/utils/thread";
import { useHydrated } from "remix-utils/use-hydrated";
import type { ThreadSort } from "~/utils/thread";
import type { AuthorPerm, ParsedAccount, ParsedAccounts } from "~/utils/hive";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePushStartArrayState } from "~/hooks/arrayStateHooks";
import { cache } from "~/utils/cache";
import { ActivePostViewContext } from "~/contexts";
import type { TokenPrices } from "~/utils/coingecko";
import { authorPermToString } from "~/utils/hive";
import addDays from "date-fns/addDays/index.js";
import { SearchBarInitiator } from "~/components/SearchBarInitiator";
import InfoHeader from "~/components/InfoHeader";
import classNames from "classnames";
import { SmallAvatar16 } from "~/components/format/SmallAvatar";
import type { PostPreviewData } from "./posts";
import { ClientOnly } from "remix-utils/client-only";
import {
  type CachedDiscussion,
  type CachedList,
  leocache
} from "~/utils/leocache";
import { isSSR } from "~/utils/ssr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faCheck,
  faChevronDown,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import Popover from "~/components/Popover";
import useOnClickOutside from "~/hooks/useClickOutside";
import { getInfraSettings } from "~/utils/infra";
import { getActiveAccount } from "~/session.server";
import { route } from "~/utils/route";
import NewToLeoFinance from "~/components/NewToLeoFinance";
import { useAppStore } from "~/store";
import callSharedCache from "~/utils/shared_cache/request";
import ListsModal from "~/components/ListsModal";
import { useTraceUpdate } from "~/hooks/useTraceUpdate";
import { Account } from "~/utils/account.type";

const RelevantPeople = React.lazy(() => {
  // @ts-ignore
  return import("~/components/threads/RelevantPeople");
});
//
// const ListsModal = React.lazy(() => {
//   // @ts-ignore
//   return import("~/components/ListsModal");
// });

export const shouldRevalidate = () => false;

export const loader: LoaderFunction = async ({ request }) => {
  const activeAccount = await getActiveAccount(request);

  if (activeAccount) {
    const path = request.url.split(request.headers.get("host") as string)[1];
    const settings = await getInfraSettings(activeAccount);

    if (
      settings &&
      (path === "/threads" || path === "/threads/") &&
      path !== `/threads/${settings.settings.default_feed}`
    ) {
      return redirect(`/threads/${settings.settings.default_feed || "foryou"}`);
    }
  }

  const [threadcasts, tags] = await Promise.all([
    leocache.getLatestThreadcasts() || [],
    leocache.trendingTags
  ]);

  return json({ threadcasts, tags, activeAccount });
};

interface ThreadsData {
  threadcasts: CachedDiscussion[];
  tags: FeaturedThreadTags;
  tokenPrices: TokenPrices;
  scotDenom: number;
  activeAccount: Account;
}

export default function Threads() {
  const { threadcasts, tags, activeAccount } = useLoaderData() as ThreadsData;
  const [settings, { activePost, setActivePost }] = useAppStore(store => [
    store.settings,
    store.threads
  ]);

  const isHydrated = useHydrated();
  const location = useLocation();

  const feed = settings.default_feed || "foryou";
  const activeSorting = useMemo(
    () => location.pathname.split("/threads/")[1] || feed,
    [location]
  );

  const filteredThreadcasts = useMemo(
    () => (threadcasts || []).filter(t => t.children >= 15),
    [threadcasts]
  );

  const [preloadAccounts, setPreloadAccounts] = useState(undefined);

  useEffect(() => {
    void (async function () {
      setPreloadAccounts(
        await cache.getAccounts(filteredThreadcasts.map(x => x.author))
      );
    })();
  }, [setPreloadAccounts]);

  return (
    <>
      <main className="w-full flex-1 pc:flex-none pc:w-8/12 border-0 tbl:border-x border-pri dark:border-pri-d shrink-0">
        {isHydrated && filteredThreadcasts?.length > 0 && preloadAccounts && (
          <ThreadcastList threadcasts={filteredThreadcasts} />
        )}

        <InfoHeader className="top-0 !backdrop-blur-none !z-1000">
          {isHydrated && <ThreadsSortingHeader activeSorting={activeSorting} />}
        </InfoHeader>
        <Outlet />
      </main>
      {isHydrated && (
        <aside className="hidden tbl:block w-full max-w-[20rem]">
          {activeAccount ? (
            <div className="sticky top-0 mt-[48px] sm:mt-0 p-3">
              <SearchBarInitiator />
              <SubscribeToPremium />
              {activePost &&
                location.pathname !== "/threads" &&
                location.pathname !== "/threads/" && (
                  <React.Suspense fallback={<></>}>
                    <RelevantPeople activePost={activePost} />
                  </React.Suspense>
                )}
              <FeaturedTags tags={tags} />
            </div>
          ) : (
            <div className="sticky top-3 mt-[48px] sm:mt-0">
              <NewToLeoFinance />
            </div>
          )}
        </aside>
      )}

      <React.Suspense fallback={<></>}>
        <ListsModal />
      </React.Suspense>
    </>
  );
}

export function SubscribeToPremium() {
  const [activeAccount, premiumState, setPremium] = useAppStore(store => [
    store.account.activeAccount,
    store.account.premium,
    store.account.setPremium
  ]);

  useEffect(() => {
    if (!activeAccount) return;
    void (async function () {
      try {
        const premium = await cache.getPremium(activeAccount.name);
        setPremium(premium);
      } catch (error) {
        console.log(`ERROR FETCHING PREMIUM`, error);
      }
    })();
  }, [activeAccount]);

  if (activeAccount && premiumState.fetched && !premiumState.is_premium) {
    return (
      <ClientOnly>
        {() => (
          <div className="relative flex flex-col p-4 mt-3 gap-6 border border-pri dark:border-pri-d bg-pri-d/[.065] dark:bg-pri/[.065] rounded-xl">
            <div className="flex flex-col gap-2">
              <h1 className="text-lg">Subscribe to Premium</h1>
              <p className="text-sm text-gray-600 dark:text-gray-400 leading-snug">
                Subscribe to unlock exclusive features and rewards.
              </p>
            </div>

            <Link prefetch="render" to="/premium" className="flex flex-1">
              <button className="flex flex-1 justify-center items-center px-4 py-2 text-sm font-semibold bg-pri-d dark:bg-pri text-pri-d dark:text-pri rounded-full transition-colors hover:bg-pri-hov-d dark:hover:bg-pri-hov">
                Subscribe
              </button>
            </Link>
          </div>
        )}
      </ClientOnly>
    );
  }

  return null;
}

export interface FetchIndex {
  (index: number, sort: ThreadSort): Promise<ThreadIndexed[]>;
}

export interface ThreadsLoader {
  threadContents: PossibleThreadContent[];
  accounts: ParsedAccounts;
  threadIndices: CachedDiscussion[];
  tagName?: string;
  ignoredUsers?: string[];
}

export interface ThreadsSession {
  index: number;
  threads?: PossibleThreadContent[];
}

export enum threadFeedTypes {
  Latest,
  Trending,
  Post
}

export const THREADS_AT_TIME = 6;

function removeDuplicates(threads: any[]) {
  const seen = new Set();
  return threads.filter(thread => {
    const permlink = thread?.content?.permlink || thread?.thread?.permlink;
    if (seen.has(permlink)) {
      return false;
    }
    seen.add(permlink);
    return true;
  });
}

export function useThreads(
  initialThreadIndices: PossibleThreadContent[],
  _threadIndices: CachedDiscussion[],
  feedType?: threadFeedTypes,
  authorPerm?: AuthorPerm
) {
  const comingBackFromThread =
    typeof route?.previous === "string"
      ? route?.previous?.startsWith("/threads/view/")
      : false;

  let threadIndicesFromSession = _threadIndices;
  let threadLoadedFromSession = initialThreadIndices;

  const [threadIndices, setThreadIndices] = useState(threadIndicesFromSession);

  const [loadedThreads, setLoadedThreads] = useState<PossibleThreadContent[]>(
    () => threadLoadedFromSession
  );

  const [isLoadingThreads, setIsLoadingThreads] = useState(false);
  const [updatedIndiceChange, setUpdatedIndiceChange] = useState(false);
  const [toBeUpdated, setToBeUpdated] = useState([]);
  const [fakeThreadsQeue, setFakeThreadsQueue] = useState([]);

  const updateThreads = useCallback(
    (newThreads: any) => {
      setLoadedThreads(prevThreads =>
        removeDuplicates([...prevThreads, ...newThreads])
      );
    },
    [setLoadedThreads]
  );

  useEffect(() => {
    if (fakeThreadsQeue?.length >= 3) {
      setFakeThreadsQueue([]);
    }
  }, [fakeThreadsQeue]);

  const handleScroll = () => {
    if (
      window.scrollY >
      document.body.scrollHeight - window.innerHeight - 500
    ) {
      //loadMore();
      pushNewThreads();
      setToBeUpdated([]);
    }
  };

  const loadedThreadsLength = React.useMemo(
    () => loadedThreads?.length,
    [loadedThreads]
  );

  const loadMore = useCallback(async () => {
    setIsLoadingThreads(true);
    try {
      const toBeLoadedCount = loadedThreadsLength + THREADS_AT_TIME;
      const slicedIndices = threadIndices.slice(
        loadedThreadsLength,
        toBeLoadedCount
      );
      const newThreads = await callSharedCache("post", slicedIndices);
      await cache.getAccountsFromThreads(slicedIndices);
      updateThreads(newThreads);
    } catch (error) {
      console.error("loadMore error", error);
    } finally {
      setIsLoadingThreads(false);
    }
  }, [loadedThreadsLength, threadIndices, updateThreads]);

  const pushToStart = useCallback(newThread => {
    setLoadedThreads(prevThreads =>
      removeDuplicates([newThread, ...prevThreads])
    );
  }, []);

  function changeIndices(newIndices: CachedDiscussion[]): void {
    setLoadedThreads([]);
    setThreadIndices(newIndices);
    setUpdatedIndiceChange(false);
  }

  const linkedFeed = async () => {
    let feedResult;

    if (feedType === threadFeedTypes.Latest) {
      feedResult = await leocache.latestFeed;
    } else if (feedType === threadFeedTypes.Trending) {
      feedResult = await leocache.trendingFeed;
    }

    const sanitizedLastLoaded = loadedThreads.find(
      thread => thread?.thread?.fake !== true
    );

    if (!feedResult) {
      return { fullFeed: [], rawFeed: [] };
    }

    const lastLoadedTime = new Date(
      sanitizedLastLoaded?.thread?.created
    ).getTime();
    const filteredEvents = feedResult
      .filter(event => new Date(event.created).getTime() > lastLoadedTime)
      .sort(
        (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
      );

    const fullFeed = await cache.getThreads(filteredEvents);

    return {
      fullFeed: fullFeed as PossibleThreadContent[],
      rawFeed: feedResult
    };
  };
  const pushNewThreads = feed => {
    const fakeThreadsApplied =
      loadedThreads.filter(thread => thread?.thread?.fake === true) || [];
    const sanitizeDuplicates = newThread =>
      loadedThreads.find(
        thread =>
          (thread?.content?.permlink || thread?.thread?.permlink) ===
          (newThread?.content?.permlink || newThread?.thread?.permlink)
      );

    const newLoadedThreads = (feed || toBeUpdated).filter(
      a => !sanitizeDuplicates(a)
    );
    if (newLoadedThreads.length === 0) return;

    newLoadedThreads.forEach(pushToStart);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [toBeUpdated]);

  useEffect(() => {
    if (isSSR()) return;
    const interval = setInterval(async () => {
      const { fullFeed: feed, rawFeed } = await linkedFeed();
      if (window.scrollY < 500) {
        pushNewThreads(feed);
        setToBeUpdated([]);
      } else {
        pushNewThreads([]);
        setToBeUpdated(feed);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [loadedThreads, linkedFeed, pushNewThreads]);

  useEffect(() => {
    if (updatedIndiceChange === false) {
      if (loadedThreads?.length === 0) loadMore();
      setUpdatedIndiceChange(true);
    }
  }, [loadedThreads]);

  useEffect(() => {
    loadMore();
  }, [setLoadedThreads]);

  return [
    loadedThreads,
    loadMore,
    pushToStart,
    changeIndices,
    isLoadingThreads,
    toBeUpdated
  ];
}

export function createFakeThread(
  pushStartToThreads: (item: PossibleThreadContent) => void,
  commentOperation: any,
  index: number,
  activeAccount: ParsedAccount
) {
  const now = new Date();
  const creationDate = "";
  const cashoutTime = addDays(now, 7).toISOString(); // TODO: 7 can be extracted as CASHOUT TIME const

  const {
    author,
    permlink,
    parent_permlink,
    parent_author,
    title,
    body,
    json_metadata
  } = commentOperation;

  setTimeout(() => {
    pushStartToThreads({
      thread: {
        index,
        author,
        permlink,
        created: now,
        replies: [],
        author_perm: authorPermToString(commentOperation),
        deleted: false,
        fake: true
      },
      content: {
        author,
        permlink,
        parent_permlink,
        parent_author,
        title,
        json_metadata,
        body,
        children: 0,
        created: creationDate,
        last_update: creationDate,
        replies: 0,
        active_votes: [],
        author_reputation: activeAccount.reputation as number,
        cashout_time: cashoutTime,
        category: "hive-167922", // TODO: extract as LEOFINANCE community,
        depth: 1,
        root_author: "leothreads"
      }
    });
  }, 500);
}

interface ThreadsSortingHeader {
  activeSorting: string;
}

const ThreadsSortingHeader = React.memo(
  ({ activeSorting }: ThreadsSortingHeader) => {
    const navigate = useNavigate();
    const location = useLocation();

    let {
      activeAccount,
      keys,
      premium: premiumState,
      setPremium
    } = useAppStore(store => store.account);

    if (!keys) {
      //null or undefined case for #343 (not sure, it's a powerful trial)
      keys = {
        signature: "",
        publicKey: "",
        proxy: null
      };
    }

    const { signature, publicKey, proxy } = keys;
    useEffect(() => {
      if (!activeAccount) return;
      void (async function () {
        try {
          const premium = await cache.getPremium(activeAccount.name);
          setPremium(premium);
        } catch (error) {
          console.log(`ERROR FETCHING PREMIUM`, error);
        }
      })();
    }, [activeAccount]);

    const [signatureFromStorage] = useState(
      !isSSR() && window.localStorage.getItem("activeAccount")
    );

    const [lists, setLists] = React.useState<CachedList[] | null>(null);
    const [listsDropdown, setListsDropdown] = React.useState<boolean>(false);
    const listsDropdownRef = React.useRef<HTMLDivElement>(null);

    const ableToUseListFeed =
      activeAccount &&
      (signature || signatureFromStorage) &&
      premiumState.is_premium;

    useEffect(() => {
      //  if (lists !== null) return;

      if (
        !activeAccount ||
        (!signature && !signatureFromStorage) ||
        !publicKey ||
        !premiumState.is_premium
      ) {
        setLists([]);
        return;
      }

      void (async function () {
        const lists = await leocache.getAuthorLists(
          activeAccount?.name,
          publicKey,
          signature || signatureFromStorage,
          proxy === "hivesigner"
        );

        if (!lists) return;

        setLists(lists);
      })();
    }, [activeAccount, premiumState, location]);

    const feeds = activeAccount
      ? ["foryou", "latest", "trending", "following"]
      : ["latest", "trending"];

    const currentFeed = React.useMemo(() => {
      return location.pathname.split("/threads/")[1];
    }, [location]);

    const list_feed = React.useMemo(() => {
      if (!currentFeed) return undefined;

      return currentFeed?.includes(":")
        ? lists?.find(
            l =>
              l.account === currentFeed.split(":")[0] &&
              l.slug === currentFeed.split(":")[1]
          )
        : undefined;
    }, [location, lists]);

    useOnClickOutside(listsDropdownRef, () => setListsDropdown(false));

    return (
      <div className="flex flex-col h-fit text-pri dark:text-pri-d font-bold w-full !z-1000">
        <div className="flex flex-row w-full text-center select-none">
          {feeds.map(sortingName => (
            <ThreadsSortingHeaderItem
              key={sortingName}
              activeSorting={activeSorting}
              sortingName={sortingName}
            />
          ))}

          {/* custom list feed */}
          {ableToUseListFeed ? (
            lists === null ? (
              <div
                className={classNames(
                  `flex flex-1 flex-wrap gap-x-2 items-center justify-center content-center
              h-14 text-sm opacity-50 cursor-not-allowed`
                )}
              >
                <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
              </div>
            ) : lists?.length > 0 ? (
              <div
                ref={listsDropdownRef}
                className="relative flex flex-1 items-center justify-center"
              >
                <button
                  type="button"
                  className="flex flex-1 flex-wrap gap-x-2 items-center justify-center content-center hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] h-14 text-sm font-semibold cursor-pointer capitalize transition-colors"
                  onClick={() => {
                    setListsDropdown(current => !current);
                  }}
                >
                  {list_feed ? (
                    <div
                      className={classNames(
                        `flex flex-1 gap-x-2 items-center justify-center content-center
                        px-1 h-14 text-sm text-pri/60 dark:text-pri-d/60 hover:text-pri dark:hover:text-pri-d font-semibold cursor-pointer capitalize transition-colors`,
                        {
                          "border-b-2 border-acc !text-pri/100 dark:text-pri-d/100":
                            activeSorting ===
                            `${list_feed.account}:${list_feed.slug}`
                        }
                      )}
                    >
                      <div className="relative flex w-5 h-5 rounded-full overflow-hidden">
                        <img
                          src={list_feed.image}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <span className="inline whitespace-nowrap max-w-[44px] sm:max-w-[94px] text-ellipsis overflow-hidden">
                        {list_feed.name}
                      </span>

                      <FontAwesomeIcon
                        icon={faChevronDown}
                        size="xs"
                        fixedWidth
                      />
                    </div>
                  ) : lists?.length > 0 ? (
                    <div
                      className={classNames(
                        `flex flex-1 gap-x-2 items-center justify-center content-center
                        px-1 h-14 text-sm text-pri/60 dark:text-pri-d/60 hover:text-pri dark:hover:text-pri-d font-semibold cursor-pointer capitalize transition-colors`
                      )}
                    >
                      <span className="inline whitespace-nowrap max-w-[90px] text-ellipsis overflow-hidden">
                        Your Lists
                      </span>

                      <FontAwesomeIcon
                        icon={faChevronDown}
                        size="xs"
                        fixedWidth
                      />
                    </div>
                  ) : (
                    <Link
                      id="add-feed-tooltip"
                      to="/lists"
                      className={classNames(
                        `flex flex-1 flex-wrap gap-x-2 items-center justify-center content-center
                        h-14 text-sm text-pri/60 dark:text-pri-d/60 hover:text-pri dark:hover:text-pri-d font-semibold cursor-pointer capitalize transition-colors`
                      )}
                    >
                      <FontAwesomeIcon icon={faAdd} fixedWidth />
                      <Popover
                        anchorId={`add-feed-tooltip`}
                        content="Add feed"
                        place="bottom"
                      />
                    </Link>
                  )}
                </button>

                {listsDropdown ? (
                  <div className="absolute top-full right-0 w-full min-w-[180px] sm:min-w-[240px] mt-0.5 rounded-lg bg-pri dark:bg-pri-d border border-pri dark:border-pri-d overflow-hidden z-1000">
                    <div className="flex flex-1 flex-col max-h-[250px] overflow-y-auto divide-y divide-pri/50 dark:divide-pri-d/50">
                      {lists.map(list => (
                        <button
                          type="button"
                          className={classNames(
                            "flex flex-1 items-center px-3.5 py-3 gap-x-2 text-sm font-medium hover:bg-pri-d/10 dark:hover:bg-pri/10 transition-colors duration-150",
                            {
                              "bg-pri-d/[.05] dark:bg-pri/[.05]":
                                list.slug === list_feed?.slug
                            }
                          )}
                          onClick={() => {
                            navigate(`/threads/${list.account}:${list.slug}`);

                            setTimeout(() => {
                              setListsDropdown(false);
                            }, 50);
                          }}
                        >
                          {list.image ? (
                            <div className="flex w-5 h-5 rounded-full bg-gray-200 dark:bg-zinc-700 overflow-hidden">
                              <img
                                src={list.image}
                                alt=""
                                className="w-5 h-5 object-cover"
                              />
                            </div>
                          ) : null}

                          <span className="flex flex-1 pr-2 text-left">
                            {list.name}
                          </span>

                          {list.slug === list_feed?.slug && (
                            <span className="flex justify-center items-center w-4 h-4 ml-auto">
                              <FontAwesomeIcon
                                icon={faCheck}
                                size="sm"
                                fixedWidth
                              />
                            </span>
                          )}
                        </button>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <Link
                id="add-feed-tooltip"
                to="/lists"
                className={classNames(
                  `flex flex-1 flex-wrap gap-x-2 items-center justify-center content-center
                  h-14 text-sm text-pri/60 dark:text-pri-d/60 hover:text-pri dark:hover:text-pri-d font-semibold cursor-pointer capitalize transition-colors`
                )}
              >
                <FontAwesomeIcon icon={faAdd} fixedWidth />
                <Popover
                  anchorId={`add-feed-tooltip`}
                  content="Add feed"
                  place="bottom"
                />
              </Link>
            )
          ) : null}
          {/* // ) : list_feed ? (
      //   <Link
      //     to={`/threads/${list_feed.account}:${list_feed.slug}`}
      //     className={classNames(
      //       `flex flex-1 flex-wrap gap-x-2 items-center justify-center content-center
      //     hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] h-14 text-sm font-semibold cursor-pointer capitalize transition-colors`,
      //       {
      //         "border-b-2 border-acc":
      //           activeSorting === `${list_feed.account}:${list_feed.slug}`
      //       }
      //     )}
      //   >
      //     <div className="relative flex w-6 h-6 rounded-full overflow-hidden">
      //       <img
      //         src={list_feed.image}
      //         alt=""
      //         className="w-full h-full object-cover"
      //       />
      //     </div>
      //     <span className="inline whitespace-nowrap max-w-[90px] text-ellipsis overflow-hidden">
      //       {list_feed.name}
      //     </span>
      //   </Link>
      // ) : activeAccount ? (
      //   <Link
      //     id="add-feed-tooltip"
      //     to="/settings#feed"
      //     className={classNames(
      //       `flex flex-1 flex-wrap gap-x-2 items-center justify-center content-center
      //       hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] h-14 text-sm font-semibold cursor-pointer capitalize transition-colors`
      //     )}
      //   >
      //     <FontAwesomeIcon icon={faAdd} fixedWidth />
      //     <Popover
      //       anchorId={`add-feed-tooltip`}
      //       content="Add feed"
      //       place="bottom"
      //     />
      //   </Link>
      // ) : null} */}
        </div>
      </div>
    );
  }
);
ThreadsSortingHeader.displayName = "ThreadsSortingHeader";

interface ThreadsSortingItem {
  activeSorting: string;
  sortingName: string;
}

function ThreadsSortingHeaderItem({
  sortingName,
  activeSorting
}: ThreadsSortingItem) {
  const activeAccount = useAppStore(store => store.account.activeAccount);

  const activeColor = React.useMemo(() => {
    return activeSorting?.includes(":")
      ? false
      : activeAccount
      ? activeSorting === sortingName
      : activeSorting === "foryou" && sortingName === "latest"
      ? true
      : activeSorting === sortingName;
  }, [activeAccount, activeSorting, sortingName]);

  return (
    <Link
      to={`/threads/${sortingName}`}
      prefetch="render"
      className={classNames(
        // { "border-b-2 border-acc": activeColor },
        { "text-pri/60 dark:text-pri-d/60": !activeColor },
        `relative flex flex-1 flex-wrap items-center justify-center content-center
        hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] hover:text-pri dark:hover:text-pri-d h-14 text-sm font-semibold cursor-pointer capitalize transition-colors`
      )}
    >
      {/* {sortingName === "foryou" && (
        <span className="relative flex h-2 w-2 mr-2">
          <span className="absolute inline-flex h-full w-full rounded-full bg-acc opacity-75"></span>
          <span className="relative inline-flex rounded-full h-2 w-2 bg-acc-hov"></span>
        </span>
      )} */}
      {sortingName === "foryou" ? "For You" : sortingName}
    </Link>
  );
}

function ThreadcastList({ threadcasts }: { threadcasts: CachedDiscussion[] }) {
  return (
    <div className="relative flex flex-1 w-full border-b mt-[48px] sm:mt-0 border-pri dark:border-pri-d overflow-hidden">
      <div className="flex flex-1 items-center w-max py-4 pl-4 gap-x-8 whitespace-nowrap overflow-auto">
        {threadcasts?.map((tc, index) => (
          <ThreadcastIndicator key={index} threadcast={tc} />
        ))}
      </div>
    </div>
  );
}

function ThreadcastIndicator({ threadcast }: { threadcast: CachedDiscussion }) {
  return <SmallAvatar16 author={threadcast.author} threadcast={threadcast} />;
}
